.case {
  position: relative;
  @extend .shadow;
  margin: map_get($spacers, 5) 0;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  &__headline {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: map_get($spacers, 5);
  }

  &__body {
    padding: 1.6rem;
    font-size: 1.125rem;

    @include media-breakpoint-up(md) {
      padding: 3.125rem;
      flex-grow: 1;
    }
  }

  &__accordion {
    &__item {
      margin: map_get($spacers, 3) 0;

      &__header {
        padding: 0;

        .btn {
          font-family: HelveticaNeue-Light, sans-serif;
          font-size: 1.25rem;
          font-weight: 500;
          text-align: left;
          width: 100%;
          padding-left: 1rem;
          padding-right: 1rem;
          display: block;
          background: $gray-200;

          &:focus {
            box-shadow: none;
            text-decoration: none;
          }

          &:hover {
            text-decoration: none;
          }

          &[aria-expanded='true'] {
            background-color: transparent;
          }
        }
      }

      &__body {
        padding: map_get($spacers, 3) map_get($spacers, 3);
      }
    }
  }

  &__image {
    order: -1;
    width: 100%;

    @include media-breakpoint-up(md) {
      order: 1;
      display: flex;
      align-items: stretch;
      justify-content: stretch;
      max-width: 40%;
      min-width: 40%;
    }

    img {
      max-width: 100%;
      width: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }
}
