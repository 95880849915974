.jumbotron {
  position: relative;
  min-height: calc(100vh - 112px);
  color: $white;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: stretch;
  border-radius: 0;
  transition: opacity 0.2s;

  &__background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';

    @include media-breakpoint-down('md') {
      display: none;
    }
  }
}

.btn-outline-primary {
  font-family: HelveticaNeue-Light, sans-serif;
  color: $black;
}

.btn.d-block {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.card {
  @include media-breakpoint-down('md') {
    flex-direction: row;
  }

  @include media-breakpoint-down('sm') {
    flex-direction: column;
  }
}

.card-body {
  @include media-breakpoint-down('md') {
    width: 60%;
  }

  @include media-breakpoint-down('sm') {
    width: 100%;
  }
}

.card-image {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  position: relative;
  max-height: 200px;

  @include media-breakpoint-down('md') {
    width: 40%;
    min-width: 40%;
    order: 1;
    max-height: none;
  }

  @include media-breakpoint-down('sm') {
    width: 100%;
    min-width: 100%;
    order: 0;
  }

  img {
    max-width: 100%;
    font-family: 'object-fit: cover;';
    @include media-breakpoint-down('md') {
      object-fit: cover;
    }
  }
}
