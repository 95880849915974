$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1440px,
);

$primary: #78368c;
$secondary: $white;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
  ),
  $theme-colors
);

$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 3,
    ),
    6: (
      $spacer * 10,
    ),
  ),
  $spacers
);

$btn-padding-y: 0.7rem;
$btn-padding-x: 6rem;

$link-color: theme-color('primary');
$link-hover-color: darken($link-color, 15%);

$lead-font-size: 1.5625rem;

$dark-text: #00071b;

//$h1-font-size: ;
$h2-font-size: 3.125rem;
//$h3-font-size: ;
//$h4-font-size: ;
//$h5-font-size: ;
//$h6-font-size: ;
