body {
  font-family: HelveticaNeue-Light, sans-serif;
  font-weight: 400;
  overflow-x: hidden;
}

.main {
  padding-top: 112px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: HelveticaNeue-Medium, sans-serif;
  font-weight: 500;
}
