header {
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 0.8rem 0.1rem $gray-500;
  position: fixed;
  width: 100%;
  z-index: 1000;
  background: $white;

  img {
    margin: -2rem 0;
    height: 6rem;
  }

  a {
    text-decoration: none !important;
  }

  h1 {
    margin: 0;
    a {
      color: $dark-text;
      font-weight: bold;
      letter-spacing: 0.7rem;
      @include media-breakpoint-down(sm) {
        font-size: 2rem;
        letter-spacing: 0.5rem;
      }

      &:hover {
        color: $dark-text;
      }
    }
  }

  .navigationWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;

    .nav-links {
      font-size: 1.125rem;
      color: $dark-text;
      font-family: HelveticaNeue-Light, sans-serif;
      transition: 0.5s ease-in-out;

      &.hidden {
        opacity: 0;
      }

      .language-links {
        display: flex;
        justify-content: flex-end;
        margin-right: 0.9rem;
        margin-bottom: 0.5rem;
        margin-top: -2rem;
      }

      a {
        margin: 0 1.2rem;
        cursor: pointer;
        color: inherit;
        transition: 0.5s;
        position: relative;

        &.language-link {
          margin: auto 0.5rem;

          img {
            height: auto;
            width: 20px;
            margin: auto;
          }
        }
        //&:hover::after {
        //  @include menuActiveBarHorizontal
        //}
        //
        //&.active:after {
        //  @include menuActiveBarHorizontal
        //}
      }

      button {
        margin: 0 1.2rem;
        background: transparent;
        border: none;
        cursor: pointer;
        outline: none;
      }
    }

    .menu-icon {
      cursor: pointer;
    }
  }
}

.menu-icon-trigger {
  cursor: pointer;
  transition: 0.5s ease-in-out;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &.hidden {
    opacity: 0;
  }
}

.menu {
  width: 100%;
  height: 100vh;
  opacity: 0;
  position: absolute;
  left: 1000rem;
  top: 0;
  transition: left 0.5s ease-in-out;
  background: $white;
  z-index: 100;

  &.open {
    opacity: 1;
    left: 0;
  }

  .menu-nav {
    padding: 2rem 0;
    box-shadow: 0 0 0.8rem 0.1rem $gray-500;

    .container {
      display: flex;
      justify-content: space-between;
    }
  }

  .listContainer {
    height: 100%;
    display: flex;

    &__leftBlock {
      flex-basis: 10%;
      background: $primary;
    }

    &__list {
      .language-links {
        flex-direction: row;
        li {
          margin: 0 1rem 0 0;
        }
      }

      a.language-link {
        margin: 1rem 1rem 1rem 0;

        img {
          height: auto;
          width: 40px;
          margin: auto;
        }
      }

      ul {
        list-style: none;
        padding-top: 5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 5rem;
        flex-basis: 90%;

        @include media-breakpoint-down(lg) {
          padding-left: 2rem;
        }

        @include media-breakpoint-down(xl) {
          padding-top: 2rem;
        }

        li {
          font-family: HelveticaNeue-Light, sans-serif;
          font-size: 2.875rem;
          margin: 1rem 0;
          @include media-breakpoint-down(lg) {
            font-size: 3.8rem;
          }
          @include media-breakpoint-down(sm) {
            font-size: 2rem;
          }

          a {
            color: $gray-800;
            position: relative;

            &:hover:before {
              content: '';
              position: absolute;
              width: 100%;
              height: 0.3rem;
              left: 0;
              right: 0;
              bottom: -1rem;
              margin: auto;
              background: $primary;
            }

            //&.active {
            //  font-family: HelveticaNeue-Bold, sans-serif;
            //}

            &.active:before {
              content: '';
              position: absolute;
              width: 100%;
              height: 0.3rem;
              left: 0;
              right: 0;
              bottom: -1rem;
              margin: auto;
              background: $primary;
            }
          }
        }
      }

      #nav-list {
        li {
          font-size: 2.2rem;
          font-family: HelveticaNeue-Light, sans-serif;
          color: $white;
          position: relative;

          @include media-breakpoint-down(lg) {
            font-size: 2rem;
          }
          @include media-breakpoint-down(sm) {
            font-size: 1.8rem;
          }
        }
      }
    }
  }

  #menu-trigger-close {
    display: flex;
    font-weight: 600;
    font-size: 1.25rem;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    visibility: visible;
    align-items: center;

    img {
      width: 40px;
    }
  }
}
