.slider {
  &__headline {
    &::after {
      content: '”';
      display: block;
      text-indent: 1em;
      letter-spacing: -1rem;
      margin-bottom: -5rem;
      font-weight: 400;
      font-size: 11.25rem;
      font-family: HelveticaNeue-Light, sans-serif;
      color: $primary;
    }
  }

  .carousel {
    padding-bottom: 3rem;
  }

  .carousel-indicators {
    justify-content: flex-start;
    margin-left: 0;
    margin-left: auto;
    bottom: -3rem;

    li {
      background-color: $gray-300;
      height: 0.5rem;
      &.active {
        background-color: $gray-500;
      }
    }
  }
}
